import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReadyState } from 'react-use-websocket';
import { usePcsClient } from 'src/context/pcs-auth-context';
import { usePcsTakeEventSocket, usePcsUserEventSocket } from './socketHooks';
import { useEffect } from 'react';
import axios from 'axios';

interface Event {
  eventId: number;
  eventTimestamp: number;
  userId: number;
  sid: number;
  pcs_status: number;
  account?: string;
  eventType?: string;
  info?: string;
  video?: Video[];
  tracks?: Track[];
  flaggedForReview?: boolean;
}

interface Video {
  clipId: number;
  uuid: string;
  preroll: number;
  postroll: number;
  cameraName: string;
  recordingType: string;
}

interface Track {
  id: number;
  type: string;
  class_name: string;
  pts_seconds: number;
  img_file?: string;
}

const MINUTES_20_IN_SECONDS = 20 * 60;
const CID_AGENT_LIVE_VIEWING = 1171;
const CID_RECORDING_VIDEO = 1170;

export function useGetVideoToken(
  sid: number,
  startTimestamp: number,
  hasPcsMetadata: boolean
) {
  const client = usePcsClient();
  const { data } = useQuery(
    ['pcs_camera_token', sid, startTimestamp],
    () => client(`camera/token-camera/${sid}/${startTimestamp}`),
    {
      select: (_data) => _data.token,
      enabled: sid != null && !hasPcsMetadata,
    }
  );

  return data;
}

export function useGetSecondaryEvents(
  sid: number,
  triggerEventId: number,
  startTimestamp: number
) {
  const client = usePcsClient();
  const endTimestamp = startTimestamp + MINUTES_20_IN_SECONDS;
  const query = useQuery(['pcs_secondary_events', sid, triggerEventId], () =>
    client(
      `subscriptions/${sid}/events?start=${startTimestamp}&end=${endTimestamp}`,
      {
        enabled: sid != null,
      }
    ),
    { enabled: !!sid && !!triggerEventId && !!startTimestamp }
  );

  const events = query.data?.events || [];
  const secondaryEvents = events.filter(
    (event) =>
      (event.eventCid === CID_AGENT_LIVE_VIEWING ||
        (event.eventCid === CID_RECORDING_VIDEO &&
          event.pcsMetadata != null)) &&
      event.eventId !== triggerEventId
  );
  return secondaryEvents;
}

export function useGetMediatorStreamingUrl(
  videoUrl?: string,
  agentToken?: string
) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    ['streaming_url', videoUrl, agentToken],
    () =>
      axios.get<{ streamingSessionURL: string }>(videoUrl, {
        headers: {
          Authorization: `Bearer ${agentToken}`,
        },
      }),
    {
      enabled: videoUrl != null && agentToken != null,
      select: (data) => data.data.streamingSessionURL,
      onError: (error) => {
        enqueueSnackbar(
          `Error getting streaming ${error.response.data.statusCode}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
}

export function useGetPcsEventQuery(eventId: number) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { readyState } = usePcsUserEventSocket({ event_id: eventId });
  const query = useQuery(
    ['pcs_events', eventId],
    () => client(`events/event/${eventId}`),
    {
      refetchInterval: readyState !== ReadyState.OPEN ? 2000 : null,
      enabled: eventId > 0,
      onError: (error) => {
        enqueueSnackbar(`Endpoint: events/event/{eventId}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return query;
}

export function useGetEventDocumentation(eventId: number) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(
    ['event_documentation', eventId],
    () => client(`events/${eventId}/documentation`),
    {
      onError: (error) => {
        if (error.response.status === 404) {
          return;
        }
        enqueueSnackbar('Could not fetch documentation');
      },
      enabled: eventId != null,
    }
  );

  return query;
}

export function useListPcsEventsQuery(params: any) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery(
    ['pcs_events', params],
    () => client(`events/list`, { params: params }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: events/list. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return query;
}

export function useTakePcsEventsQuery(
  limit = 9,
  accept = false,
  lookback = 60 * 5,
  pcs_status_gte = 0,
  threat_level_gte = 0,
  armstate = 'All'
) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const { readyState } = usePcsTakeEventSocket(accept);
  const query = useQuery(
    [
      'pcs_take_events',
      {
        params: {
          limit,
          accept,
          lookback,
          pcs_status_gte,
          threat_level_gte,
          armstate,
        },
      },
    ],
    () =>
      client(`events/take`, {
        params: {
          limit,
          accept,
          lookback,
          pcs_status_gte,
          threat_level_gte,
          armstate,
        },
      }),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint events/take. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
  return query;
}

export function useUnsetEventsTaken() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { verify_eventId: number | null }) =>
      client(
        data.verify_eventId
          ? `events/unset-taken?verify_eventId=${data.verify_eventId}`
          : `events/unset-taken`,
        {
          method: 'POST',
          // data: data
        }
      ),
    {
      onSuccess: () => {
        cache.invalidateQueries('pcs_event');
      },
      onError: (error, variables) => {
        enqueueSnackbar(
          `Error un-setting events taken ${variables}: ${error}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
  return mutation;
}

export function useFlagEventForReview(eventId: number) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(
    (data: { flag: boolean }) =>
      client(`events/${eventId}/flag-ai`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries(['pcs_events', eventId]);
      },
      onError: (error) => {
        enqueueSnackbar(`Error saving flag for ai review: ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useSetDispositionAction(eventId: number) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { action: string }) =>
      client(`events/disposition-action/${eventId}?action=${data.action}`, {
        method: 'POST',
        // data: data
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries(['pcs_events', eventId]);
      },
      onError: (error, variables) => {
        enqueueSnackbar(
          `Error saving dispostion ${variables.action}: ${error}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
  return mutation;
}

export function useSetDispositionThreatLevel(eventId: number) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { threat_level: number }) =>
      client(
        `events/disposition-threat-level/${eventId}?threat_level=${data.threat_level}`,
        {
          method: 'POST',
          // data: data
        }
      ),
    {
      onSuccess: () => {
        cache.invalidateQueries(['pcs_event', { eventId: eventId }]);
      },
      onError: (error, variables) => {
        enqueueSnackbar(
          `Error saving threat level ${variables.threat_level}: ${error}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
  return mutation;
}

export function useSetEventDocumentation(eventId: number) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { documentation: string }) =>
      client(`events/${eventId}/documentation`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries(['pcs_event', { eventId: eventId }]);
      },
      onError: (error, variables) => {
        enqueueSnackbar(
          `Error saving documentation ${variables.documentation}: ${error}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );
  return mutation;
}

export function useEventDispatchIncident(eventId: number) {
  const client = usePcsClient();
  const mutation = useMutation(
    (data: { eventCid: string; zoneCid: string; description: string }) =>
      client(`events/dispatch-klaxon/${eventId}`, {
        method: 'POST',
        data,
      })
  );
  return mutation;
}

interface EventStatusUpdate {
  eventId: number;
  pcsStatus: number;
  disposition_action?: string;
  disposition_reason?: string;
  event_documentation?: string;
}

export function useSetPcsEventStatus() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (eventStatusUpdate: EventStatusUpdate) =>
      client(`events/pcs-status/${eventStatusUpdate.eventId}`, {
        method: 'POST',
        params: {
          pcsStatus: eventStatusUpdate.pcsStatus,
          disposition_reason: eventStatusUpdate.disposition_reason || '',
          disposition_action: eventStatusUpdate.disposition_action || '',
          event_documentation: eventStatusUpdate.event_documentation || '',
        },
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries('pcs_events');
        cache.invalidateQueries('pcs_take_events');
      },
      onError: (error) => {
        enqueueSnackbar(`Endpoint: events/pcs-status/{eventId}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

/** DETECTIONS */
export function useSetDetectionFlagMutation(eventId: number) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: {
      category: string;
      detectionId: string;
      flag: string;
      state: boolean;
    }) =>
      client(
        `events/update/${eventId}/detection/${data.category}/${data.detectionId}?flag=${data.flag}&state=${data.state}`,
        {
          method: 'POST',
          // data: data
        }
      ),
    {
      onSuccess: () => {
        cache.invalidateQueries(['pcs_events', eventId]);
      },
      onError: (error, variables) => {
        enqueueSnackbar(`Error saving ${variables.category}: ${error}`, {
          variant: 'error',
        });
      },
    }
  );

  return mutation;
}

export function useSetEventNewDetection() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  // const cache = useQueryClient();
  const mutation = useMutation(
    (data: {
      eventId: number;
      pts_seconds: number;
      annotation: any;
      image: string;
    }) =>
      client(`events/update/${data.eventId}/new-detection`, {
        method: 'POST',
        data: {
          pts_seconds: data.pts_seconds,
          annotation: data.annotation,
          image: data.image,
        },
      }),
    {
      onError: (error) => {
        enqueueSnackbar(
          `Endpoint: events/update/{eventId}/new-detection. ${error}`,
          {
            variant: 'error',
          }
        );
      },
      // onSuccess: (data, variables) => {
      //   cache.invalidateQueries('motion_zone');
      //   cache.setQueryData(
      //     ['motion_zone', variables.sid, variables.uuid],
      //     data
      //   );
      // }
    }
  );

  return mutation;
}

// CLUSTERS
export function useGetPcsEventCluster(clusterId: string) {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['reid_clusters', clusterId],
    () => client(`events/cluster/${clusterId}`),
    {
      onError: (error) => {
        enqueueSnackbar(`Endpoint: events/cluster/{clusterId}. ${error}`, {
          variant: 'error',
        });
      },
    }
  );
}

export function useAuthorizeCluster() {
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(
    (data: { clusterId: string; authorized_time: number }) =>
      client(`events/authorize-cluster/${data.clusterId}`, {
        method: 'POST',
        params: {
          authorized_time: data.authorized_time,
        },
      }),
    {
      onError: (error) => {
        enqueueSnackbar(
          `Endpoint: events/authorize-cluster/{clusterId}. ${error}`,
          {
            variant: 'error',
          }
        );
      },
    }
  );

  return mutation;
}

export function useHoldEvents(eventId, sid) {
  
  const client = usePcsClient();
  const { enqueueSnackbar } = useSnackbar();

  // Use Effect so we only call this once on page load
  return useEffect(() => {

    if (eventId && sid) {
      client(`events/hold-events`, {
        method: 'POST',
        params: { sid, eventId },
      }).catch((err) => {
        enqueueSnackbar(`Error hold-events: ${err}`, {
          variant: 'error',
        });
      });
    }
  }, [eventId, sid]);
  
}

export function useUnholdEvents(eventId, sid) {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    () =>
      client(`events/unhold-events`, {
        method: 'POST',
        params: { sid, eventId },
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries('pcs_take_events');
      },
      onError: (error) => {
        enqueueSnackbar(`Error unhold-events: ${error}`, {
          variant: 'error',
        });
      },
    }
  );
}
