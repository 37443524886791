import * as React from 'react';
import { useKvsCamera } from './kvs-camera/useKvsCamera';
import { useEffect, useRef } from 'react';
import {
  getLocalStreamSource,
  playSiren,
  playStart,
  playStop,
  playVideoComponent,
} from './kvs-camera/AudioUtils';
import { AudioAccessDenied, useAudioAccessDenied } from './AudioAccessDenied';
import MetricsModule from 'src/utils/MetricsModule';
import { MetricsContext } from 'src/context/Metrics-context';
import { log } from '../../context/Logs';
import { PcsSession } from './kvs-camera/PcsSession';
import { KvsSessionInfo } from './kvs-camera/KvsSessionInfo';
import { ScreenContext } from 'src/context/ScreenRecordingContext';
import * as protobufs from './protobufs/protobufs';
import * as uuidv4 from 'uuid'; // non-standard import to avoid conflict with device uuid

type Props = {
  style: any;
  authHeader: string;
  uuid: string;
  sid: number;
  isTalking: boolean;
  isSiren: boolean;
  retryVideo: number;
  onConnectionState: (connectionState: string | null) => void;
  eventId?: number;
  fullDuplexAudio?: boolean;
};
type Ref = HTMLVideoElement;
export const KvsPlayer = React.forwardRef<Ref, Props>(
  (
    {
      uuid,
      sid,
      authHeader,
      isSiren,
      isTalking,
      retryVideo,
      onConnectionState,
      style,
      eventId,
      fullDuplexAudio
    }: Props,
    ref
  ) => {
    // <video> element (videoRef currently not used...)
    const videoRef = React.useRef<HTMLVideoElement | null>(null);
    const pcsSessionRef = React.useRef<PcsSession>(
      new PcsSession(uuid, sid, eventId || null)
    );
    const refHandler = React.useCallback(
      (node: HTMLVideoElement) => {
        videoRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          (ref as React.MutableRefObject<HTMLVideoElement>).current = node;
        }
      },
      [ref]
    );
    const localStreamRef = useRef<MediaStreamAudioSourceNode>(null);

    const kvsCamera = useKvsCamera(
      {
        uuid,
        sid,
        authHeader,
        fullDuplexAudio
      },
      pcsSessionRef.current
    );

    const audioAccessDeniedState = useAudioAccessDenied();
    const cameraWakesUpMetric = React.useRef(null);
    const cameraWakesUpMetricSend = React.useRef(false);
    const { remoteStream, setRemoteStream } = React.useContext(ScreenContext);
    React.useEffect(() => {
      (async () => {
        cameraWakesUpMetricSend.current = false;
        if (videoRef.current) {
          try {
            videoRef.current.pause();
            videoRef.current.srcObject = kvsCamera.remoteStream;
            if (kvsCamera.remoteStream) {
              setRemoteStream({
                ...remoteStream,
                ...{ [uuid]: kvsCamera.remoteStream },
              });
            }
            await playVideoComponent(videoRef.current);
            console.log('video started playing');
            cameraWakesUpMetric.current = new MetricsModule('Camera Wakes up');
            metricUpdate(cameraWakesUpMetric.current);
          } catch (e) {
            const error = 'Error playing the video';
            console.error(error, e);
            log(
              {
                message: `on KvsPlayer error camera: ${uuid}, sid: ${sid}, error: '${error}'`,
                data: {
                  uuid,
                  sid,
                  error,
                },
              },
              e
            );
          }
        }
      })();
    }, [kvsCamera.remoteStream]);

    const connectAudio = async () => {
      if (!kvsCamera.audioDestination) return;
      try {
        kvsCamera.localStream?.getAudioTracks()?.forEach(track => {
          track.enabled = true;
          kvsCamera.remoteStream?.addTrack(track);
        });
        localStreamRef.current = await getLocalStreamSource(
          { audio: true },
          kvsCamera.audioContext
        );
        localStreamRef.current.connect(kvsCamera.audioDestination);
      } catch (e) {
        const error = 'Could not get audio access permission';
        console.error(error);
        log(
          {
            message: `on KvsPlayer error camera: ${uuid}, sid: ${sid}`,
            data: {
              uuid,
              sid,
              error,
            },
          },
          e
        );
        audioAccessDeniedState.open();
      }
    };

    const disconnectAudio = () => {
      localStreamRef.current?.mediaStream
        ?.getTracks()
        ?.forEach((track) => {
          track.enabled = false;
          track.stop();
        });
      localStreamRef.current?.disconnect();
      kvsCamera.localStream?.getAudioTracks()?.forEach(track => {
        track.enabled = false;
        kvsCamera.remoteStream?.removeTrack(track);
      });
    };

    React.useEffect(() => {
      disconnectAudio();

      return () => {
        disconnectAudio();
        kvsCamera.close();
      };
    }, []);

    const [_, dispatch] = React.useContext(MetricsContext);
    const pcs_watch_settings = React.useRef(null);
    const refreshCountMetric = React.useRef(null);
    const metricUpdate = (metric) => {
      pcs_watch_settings.current = JSON.parse(
        localStorage.getItem('pcs_watch_settings')
      );
      if (typeof pcs_watch_settings.current === 'object') {
        metric.fetchNewData({ ...pcs_watch_settings.current, eventId });
      } else {
        metric.fetchNewData();
      }
      metric.stopTimer();
    };

    const callOpenCamera = async () => {
      let retryCount = 3;

      async function openCamera() {
        if (retryCount) {
          --retryCount;
          console.log(
            '***********************\n***********************\n***********************'
          );
          console.log(`****** Connecting, remaining retries ${retryCount}`);
          console.log(
            '***********************\n***********************\n***********************'
          );

          // open camera in full duplex mode if the camera supports it
          if (fullDuplexAudio) {
            await kvsCamera.openFullDuplex(openCamera);
          }
          else {
            await kvsCamera.open(openCamera);
          }

        } else {
          console.log(
            '***********************\n***********************\n***********************'
          );
          console.log('**** Could not connect');
          console.log(
            '***********************\n***********************\n***********************'
          );
        }

        if (retryCount < 2) {
          refreshCountMetric.current = new MetricsModule(
            'Refresh Count by Camera'
          );
          metricUpdate(refreshCountMetric.current);
          dispatch({
            type: 'SEND',
            payload: {
              ...refreshCountMetric.current.payload(),
              eventID: eventId,
              cameraUUID: uuid,
            },
          });
        }
      }

      await openCamera();
    };
    const connect = async () => {
      disconnectAudio();
      if (!kvsCamera.connecting) {
        await callOpenCamera();
      }
    };

    const updateStatus = () => {
      if (kvsCamera.connecting) {
        onConnectionState('Connecting...');
      } else if (kvsCamera.connected) {
        onConnectionState(null);
      } else {
        onConnectionState('Not connected');
      }
    };

    const handleStartTalking = async () => {

      await kvsCamera.audioContext?.resume();
      await connectAudio();

      // When fullDuplexAudio is not available, play the start and stop chimes through voice audio channel
      if (localStreamRef.current && kvsCamera.audioDestination && !fullDuplexAudio) {
        await playStart(kvsCamera.audioDestination, kvsCamera.audioContext);
      }

      // When fullDuplexAudio is available, use a datachannel to send mute/unmute commands
      // Also lower the siren if active when agent is trying to talk
      // Start and stop chimes are handled on-camera by firmware
      else if (localStreamRef.current && kvsCamera.audioDestination && fullDuplexAudio) {
        console.log('KvsPlayer - sending message: un-mute command');
        // Agent un-mutes
        // Siren down
        const message: protobufs.simplisafe.cameras.messages.v1.ICameraMessage = {
          header: {
            timestamp: Math.floor(Date.now() / 1000),
            xRequestId: uuidv4.v4(),
            sender: 'odmon',
          },
          clientState: {
            fullDuplexMode: protobufs.simplisafe.cameras.v1.FullDuplexMode.FULL_DUPLEX_MODE_ENABLED,
            quietMode: protobufs.simplisafe.cameras.v1.QuietMode.QUIET_MODE_ENABLED,
          },
          cameraState: undefined,
        }
        kvsCamera.sendDataChannelMessage(message);
      }

    };

    const handleStopTalking = async () => {

      if (localStreamRef.current && kvsCamera.audioDestination && !fullDuplexAudio) {
        await playStop(kvsCamera.audioDestination, kvsCamera.audioContext);
      }

      else if (localStreamRef.current && kvsCamera.audioDestination && fullDuplexAudio) {
        console.log('KvsPlayer - sending message: mute command')
        // Agent mutes
        // Siren up
        const message: protobufs.simplisafe.cameras.messages.v1.ICameraMessage = {
          header: {
            timestamp: Math.floor(Date.now() / 1000),
            xRequestId: uuidv4.v4(),
            sender: 'odmon',
          },
          clientState: {
            fullDuplexMode: protobufs.simplisafe.cameras.v1.FullDuplexMode.FULL_DUPLEX_MODE_DISABLED,
            quietMode: protobufs.simplisafe.cameras.v1.QuietMode.QUIET_MODE_DISABLED,
          },
          cameraState: undefined,
        }
        kvsCamera.sendDataChannelMessage(message);
      }

      disconnectAudio();
    };

    // @note: this queue avoids race condition between start and stop the audio from microphone button
    const [micToggleQueue, setMicToggleQueue] = React.useState([]);
    const isQueueProcessing = useRef(false);

    useEffect(() => {
      setMicToggleQueue((prevQueue) => [
        ...prevQueue,
        isTalking ? handleStartTalking : handleStopTalking,
      ]);
    }, [isTalking]);

    useEffect(() => {
      const processQueue = async () => {
        if (!isQueueProcessing.current && micToggleQueue.length > 0) {
          isQueueProcessing.current = true;
          const nextAction = micToggleQueue[0];
          if (nextAction != null) {
            await nextAction();
          }
          isQueueProcessing.current = false;
          setMicToggleQueue((prevQueue) => prevQueue.slice(1));
        }
      };

      processQueue();
    }, [micToggleQueue]);

    useEffect(() => {
      (async () => {
        if (isSiren) {
          await handleSiren();
        }
      })();
    }, [isSiren]);

    const handleSiren = async () => {
      if (!kvsCamera.audioDestination) return;
      await kvsCamera.audioContext?.resume();
      await playSiren(kvsCamera.audioDestination, kvsCamera.audioContext);
    };

    React.useEffect(() => {
      if (retryVideo > 0) {
        connect()
          .then(() => {
            console.log('KVS camera opened');
          })
          .catch((e) => {
            const error = 'KVS camera error opening';
            console.error(error, e);
            log(
              {
                message: `on KvsPlayer error camera: ${uuid}, sid: ${sid}`,
                data: {
                  uuid,
                  sid,
                  error,
                },
              },
              e
            );
          });
      }
    }, [retryVideo]);
    React.useEffect(() => {
      updateStatus();
    }, [kvsCamera.connected, kvsCamera.connecting]);

    return (
      <>
        <AudioAccessDenied {...audioAccessDeniedState.props} />
        <video
          ref={refHandler}
          onPause={() => pcsSessionRef.current.livestreamPause()}
          autoPlay={true}
          className="react-video-player"
          controls={false}
          preload="auto"
          style={{ width: '100%', backgroundColor: '#111', ...style }}
        />
        {kvsCamera.connected && isTalking && (
          <span
            style={{
              zIndex: 100,
              width: '15px',
              height: '0',
              position: 'absolute',
              right: '1vw',
              bottom: '0px',
              borderWidth: '3px',
              borderStyle: 'solid',
              borderRadius: '4px',
              borderColor: localStreamRef.current ? '#2bff00' : '#ff0000',
            }}
          />
        )}
        <div
          style={{
            position: 'absolute',
            top: 40,
            right: 10,
            bottom: 44,
            display: 'flex',
            gap: 2,
            alignItems: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <KvsSessionInfo pcsSession={pcsSessionRef} />
        </div>
      </>
    );
  }
);
