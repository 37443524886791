import { useEffect, useContext, useCallback } from 'react';
import {
  MetricsContext,
  createOpenMetricDispatch,
  getEventInQueueDuration,
} from 'src/context/Metrics-context';

const INTERVAL_TIMER = 15 * 60 * 1000; // 15 mins

interface EventHandleTimeMetricsProps {
  eventId: number | null;
  acceptNew: boolean;
  postInterval?: number; // Configurable interval in milliseconds
}

export const useEventHandleTimeMetrics = ({
  eventId,
  acceptNew,
  postInterval = INTERVAL_TIMER,
}: EventHandleTimeMetricsProps) => {
  const [, dispatch] = useContext(MetricsContext);

  const postEventHandleTimeMetric = useCallback(() => {
    if (eventId) {
      const openMetricDispatch = createOpenMetricDispatch(dispatch);
      openMetricDispatch({
        metricName: 'event-handle-time',
        payload: {
          eventId,
          duration: getEventInQueueDuration(eventId),
          PCSstatus: 'queue',
        },
      });
    }
  }, [dispatch, eventId]);

  // Post metric at a configurable interval
  useEffect(() => {
    if (!acceptNew) {
      postEventHandleTimeMetric();
    }

    const timer = setInterval(() => {
      postEventHandleTimeMetric();
    }, postInterval);

    window.addEventListener('beforeunload', postEventHandleTimeMetric);

    return () => {
      clearInterval(timer);
      window.removeEventListener('beforeunload', postEventHandleTimeMetric);
    };
  }, [postInterval, postEventHandleTimeMetric, acceptNew]);

  useEffect(() => {
    return () => {
      postEventHandleTimeMetric();
    };
  }, [postEventHandleTimeMetric]);
};
