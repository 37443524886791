import { Grid, makeStyles } from '@material-ui/core';
import React, {
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { ITrack } from 'src/components/DataStream/types';

import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import VideoPlayer from '../../../../components/VideoPlayer';
import { useGetCamerasMonitored } from '../../../../hooks/subscriberHooks';
import {
  cameraIsPrivate,
  cameraIsWired,
  cameraStreamType,
} from '../../../../utils/camera';
import { ICamera, IPlayerState } from '../../types';
import PrivateCamera from '../PrivateCamera';
import CameraGridTile from '../CameraGridTile/CameraGridTile';
import { SsCamera } from 'src/types';

const useStyles = makeStyles({
  containerHeight: {
    overflowY: 'scroll',
    height: '100%',
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    textTransform: 'uppercase',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  cameraDefault: {
    height: 'calc(100% - 20px)',
  },
  cameraWrapper: {
    position: 'absolute',
    top: '1.5em', // this is tied to height of text in "dense card header" (or we get rid of header and use top: 0)
    left: '4px',
    width: 'calc(100% - 8px)',
    height: 'calc(67vh - 23px)',
    overflow: 'hidden',
  },
});

interface IProps {
  cameras: SsCamera[];
  videoTimestamp: string;
  playerState: IPlayerState;
  activeVideo: string;
  setActiveVideo: (uuid: string) => void;
  sid: number;
  uuid: string;
  liveIndicator?: boolean;
  setVideoTimestamp: any;
  setLiveIndicator: any;
  eventId?: number;
  onItemClick: (track: ITrack | number) => void;
}

const CamerasGrid: FC<IProps> = ({
  cameras, // These cameras are coming from Yoda
  videoTimestamp,
  playerState,
  activeVideo = '',
  liveIndicator = false,
  setActiveVideo,
  sid,
  uuid,
  setVideoTimestamp,
  setLiveIndicator,
  eventId = null,
  onItemClick,
}) => {
  const classes = useStyles();
  const { data: pcsCameras, isLoading: isLoadingCamerasFromPcs } =
    useGetCamerasMonitored(sid);
  const [showAll, setShowAll] = useState(false);

  const onPrivateCameraClickHandler = () => {
    setShowAll(true);
  };

  const onCameraChangeClickHandler = useCallback(
    (e: SyntheticEvent, cameraUuid) => {
      e.stopPropagation();
      setActiveVideo(cameraUuid);

      // Commenting as requested in:
      // https://www.notion.so/wsv/Fix-warning-Expected-args-value-arg-to-be-a-Number-46b424b8e87a4dbf8b17749be687afc6
      // ---------------------------------------
      // ReactGA.event({
      //   category: 'Verify & Respond page',
      //   action: 'Changing camera',
      //   value: cameraUuid,
      // });
    },
    []
  );

  if (!cameras || isLoadingCamerasFromPcs) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <LoadingSpinner message="Loading cameras" />
        </Grid>
      </Grid>
    );
  }

  const onlineMonitoredPcsCameras = cameras.filter((camera) => {
    const pcsCamera = pcsCameras.find((item) => item.uuid === camera.uuid);
    return pcsCamera && pcsCamera.monitored;
  });

  const cameraMapMic = cameras?.reduce((acc, camera) => {
    return { ...acc, [camera.uuid]: !!camera?.cameraSettings?.micEnable };
  }, {});

  return (
    <Grid container spacing={1} className={classes.containerHeight}>
      {onlineMonitoredPcsCameras?.map((camera) => {
        // Add clientType for VDP full duplex audio mode
        // We have access to camera feature flags here, and avoids prop drilling another one-off to FlvPlayer.js
        // FLV Player is also used for all recorded video, so we only add for VDP cameras
        const url = `${process.env.REACT_APP_SS_MEDIA_URL}/v1/${camera.uuid}/flv?audioEncoding=AAC`;
        const urlWithClientType =
          camera.supportedFeatures.fullDuplexAudio && camera.model == 'SS002'
            ? `${url}&clientType=agent`
            : url;

        const active = activeVideo === camera.uuid;
        let element: ReactElement;

        if (!playerState.authHeader) {
          element = <LoadingSpinner />;
        } else if (cameraIsPrivate(camera)) {
          element = <PrivateCamera onClick={onPrivateCameraClickHandler} />;
        } else if (
          !cameraIsPrivate(camera) ||
          showAll ||
          videoTimestamp !== ''
        ) {
          element = (
            <div className={'fs-exclude'}>
              <VideoPlayer
                {...playerState}
                controls={[
                  'play',
                  'time',
                  'progress',
                  'volume',
                  'siren',
                  'talk',
                  ...(cameraStreamType(camera) === 'kvs' ? ['reload'] : []),
                  ...(camera?.supportedFeatures?.spotlightManualControl
                    ? ['spotlight']
                    : []),
                ]}
                eventId={eventId}
                forceIsPlaying={active ? undefined : true}
                disableVolumeAndMicrophone={!cameraMapMic[camera.uuid]}
                forceIsTalking={active ? undefined : false}
                handleZoom={active}
                key={`${camera.uuid}-${videoTimestamp}`} // force video player to re-init, if videoTimestamp changes
                liveIndicator={liveIndicator}
                mesuredView={'Verify&Respose'}
                onItemClick={onItemClick}
                setLiveIndicator={setLiveIndicator}
                setVideoTimestamp={setVideoTimestamp}
                sid={camera.sid}
                type={cameraStreamType(camera)}
                url={urlWithClientType}
                uuid={camera.uuid}
                active={active}
                fullDuplexAudio={camera?.supportedFeatures?.fullDuplexAudio}
              />
            </div>
          );
        } else {
          element = <div>unknown state</div>;
        }

        if (!element) {
          return null;
        }

        return (
          <CameraGridTile
            key={camera.uuid}
            camera={camera}
            active={active}
            onCameraChangeClickHandler={onCameraChangeClickHandler}
          >
            {element}
          </CameraGridTile>
        );
      })}
    </Grid>
  );
};

export default CamerasGrid;
